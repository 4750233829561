const AlertIcon = () => {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="fill-error-500"
    >
      <path d="M1.61719 19.7062C1.54219 19.8375 1.5 19.9875 1.5 20.1422C1.5 20.6203 1.88438 21.0047 2.3625 21.0047H21.6375C22.1156 21.0047 22.5 20.6203 22.5 20.1422C22.5 19.9875 22.4578 19.8422 22.3828 19.7062L12.9609 3.55313C12.7594 3.21094 12.3938 3 12 3C11.6062 3 11.2406 3.21094 11.0391 3.55313L1.61719 19.7062ZM0.323438 18.9469L9.74531 2.79375C10.2141 1.99219 11.0719 1.5 12 1.5C12.9281 1.5 13.7859 1.99219 14.2547 2.79375L23.6766 18.9469C23.8875 19.3078 24 19.7203 24 20.1375C24 21.4406 22.9406 22.5 21.6375 22.5H2.3625C1.05938 22.5 0 21.4406 0 20.1375C0 19.7203 0.1125 19.3078 0.323438 18.9469ZM12 7.5C12.4125 7.5 12.75 7.8375 12.75 8.25V14.25C12.75 14.6625 12.4125 15 12 15C11.5875 15 11.25 14.6625 11.25 14.25V8.25C11.25 7.8375 11.5875 7.5 12 7.5ZM10.875 18C10.875 17.7016 10.9935 17.4155 11.2045 17.2045C11.4155 16.9935 11.7016 16.875 12 16.875C12.2984 16.875 12.5845 16.9935 12.7955 17.2045C13.0065 17.4155 13.125 17.7016 13.125 18C13.125 18.2984 13.0065 18.5845 12.7955 18.7955C12.5845 19.0065 12.2984 19.125 12 19.125C11.7016 19.125 11.4155 19.0065 11.2045 18.7955C10.9935 18.5845 10.875 18.2984 10.875 18Z" />
    </svg>
  );
};

export default AlertIcon;
