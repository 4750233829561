import React, { useState, useRef, useEffect, useCallback } from "react";

interface SearchInputProps {
  createButtonOnClick: (query: string) => void;
  disabled?: boolean;
  value?: string;
  onSearchChange?: (value: string) => void;
}

const SearchInput: React.FC<SearchInputProps> = ({
  createButtonOnClick,
  disabled = false,
  value,
  onSearchChange,
}) => {
  const [height, setHeight] = useState("40px");
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const MAX_HEIGHT = 200;

  const handleInput = useCallback(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = ""; /* Reset the height*/
      textareaRef.current.style.height =
        Math.min(textareaRef.current.scrollHeight, MAX_HEIGHT) + "px";

      setHeight(Math.min(textareaRef.current.scrollHeight, MAX_HEIGHT) + "px");

      textareaRef.current.style.overflowY =
        textareaRef.current.scrollHeight > MAX_HEIGHT ? "scroll" : "hidden";
    }

    if (onSearchChange) {
      onSearchChange(textareaRef.current?.value || "");
    }
  }, [onSearchChange]);

  useEffect(() => {
    handleInput();
  }, [value, handleInput]);

  const handleKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === "Enter" && !e.shiftKey && !disabled) {
      e.preventDefault();
      handleButtonClick();
    }
  };

  const handleButtonClick = () => {
    if (textareaRef.current) {
      createButtonOnClick(textareaRef.current.value);
    }
  };

  return (
    <div
      className="flex items-center border border-neutral-500 rounded-lg overflow-hidden w-full max-w-[800px] mb-4"
      style={{ height, opacity: disabled ? "50%" : "100%" }}
    >
      <div className="pl-3">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5 text-neutral-500"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 21l-4.35-4.35M18 10a8 8 0 11-16 0 8 8 0 0116 0z"
          />
        </svg>
      </div>
      <textarea
        ref={textareaRef}
        placeholder="Give me a list of patients who have high cholesterol."
        className="text-text-md font-normal px-3 py-2 flex-grow resize-none border-none outline-none overflow-y-hidden"
        rows={1}
        style={{
          height: "100%",
          lineHeight: "1.5",
        }}
        value={value}
        onInput={handleInput}
        disabled={disabled}
        onKeyDown={handleKeyDown}
      />
      <button
        className="px-3 py-2 text-white bg-grapeCrush-500 hover:bg-grapeCrush-600 flex items-center gap-1 h-full"
        onClick={handleButtonClick}
      >
        <span>Create</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M5 12h14M12 5l7 7-7 7"
          />
        </svg>
      </button>
    </div>
  );
};

export default SearchInput;
