import React from "react";
import PieChartComponent from "./PieChart";
import BarChartComponent from "./BarChart";
import TopChartComponent from "./TopChart";
import TableChartComponent from "./Table";
import ErrorComponent from "./ErrorComponent";
import HeatmapChartComponent from "./HeatmapChart";

interface ChartAdapterProps {
  chartType: "bar" | "pie" | "top" | "table" | "heatmap" | "error";
  chartData: any;
  additional: any;
  error?: string;
}

const ChartAdapter: React.FC<ChartAdapterProps> = ({
  chartType,
  chartData,
  additional,
  error,
}) => {
  switch (chartType) {
    case "pie":
      return (
        <PieChartComponent
          data={chartData}
          maxCategories={additional?.maxCategories}
          valueKey={additional?.valueKey}
          nameKey={additional?.nameKey}
        />
      );

    case "bar":
      return (
        <BarChartComponent
          data={chartData}
          valueLabel={additional.valueLabel}
          valueKey={additional?.valueKey}
          nameKey={additional?.nameKey}
        />
      );

    case "top":
      return (
        <TopChartComponent
          data={chartData}
          valueLabel={additional.valueLabel}
          valueKey={additional?.valueKey}
          nameKey={additional?.nameKey}
        />
      );

    case "table":
      return (
        <TableChartComponent data={chartData} columns={additional.columns} />
      );

    case "heatmap":
      return (
        <HeatmapChartComponent
          data={chartData}
          columnTitleKey={additional.columnTitleKey}
          rowTitleKey={additional.rowTitleKey}
          valueKey={additional.valueKey}
          minValue={additional.minValue}
          maxValue={additional.maxValue}
        />
      );

    case "error":
      return (
        <ErrorComponent
          error={error || "An error occurred while fetching data"}
        />
      );

    default:
      return <div>Unsupported chart type</div>;
  }
};

export default ChartAdapter;
