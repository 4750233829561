import React from "react";

interface TableComponentProps {
  data: { [key: string]: string | number }[]; // The data should be an array of objects
  columns: { key: string; value: string }[]; // The column headers for the table
}

const TableComponent: React.FC<TableComponentProps> = ({ data, columns }) => {
  const getTableCellValue = (value: string | number | boolean | object) => {
    if (typeof value === "object") {
      return JSON.stringify(value);
    } else if (typeof value === "boolean") {
      return value ? "✅" : "❌";
    } else if (typeof value === "number") {
      return value?.toFixed(3);
    } else {
      return value?.toString();
    }
  };

  return (
    <div className="overflow-x-auto w-full min-h-[300px]">
      <div className="bg-white rounded-lg">
        <table className="min-w-full text-sm text-left">
          <thead className="bg-neutral-100 sticky">
            <tr>
              {columns.map((col, index) => (
                <th
                  key={index}
                  className="px-4 py-2 text-neutral-700 font-semibold border-b border-neutral-300"
                >
                  {col.value}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <tr key={rowIndex} className="border-b border-neutral-200">
                {columns.map((col, colIndex) => (
                  <td key={colIndex} className="px-4 py-2 text-neutral-600">
                    {getTableCellValue(row[col.key])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableComponent;
