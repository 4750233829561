const MedeLogo = () => {
  return (
    <svg
      width="76"
      height="84"
      viewBox="0 0 76 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.9995 24.878C28.3014 24.878 20.4396 32.7398 20.4396 42.4379C20.4396 46.0555 21.5335 49.4176 23.4087 52.2115C25.2838 55.0053 37.9995 66.8023 37.9995 66.8023C37.9995 66.8023 50.7152 55.0053 52.5904 52.2115C54.4655 49.4176 55.5594 46.0555 55.5594 42.4379C55.5594 32.7398 47.6976 24.878 37.9995 24.878Z"
        fill="#0C2032"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M37.9995 66.8023C38.5967 67.446 38.5967 67.4459 38.5968 67.4458L38.5973 67.4454L38.7409 67.3119C38.8348 67.2245 38.9727 67.0961 39.1487 66.9318C39.5008 66.603 40.0057 66.1305 40.6174 65.5547C41.8406 64.4035 43.4916 62.8387 45.2019 61.186C46.9113 59.5341 48.6841 57.7902 50.1493 56.2817C51.5916 54.7967 52.7981 53.4774 53.3194 52.7008C55.2886 49.7668 56.4374 46.2352 56.4374 42.4379C56.4374 32.2549 48.1825 24 37.9995 24C27.8165 24 19.5616 32.2549 19.5616 42.4379C19.5616 46.2352 20.7104 49.7668 22.6796 52.7008C23.2009 53.4774 24.4074 54.7967 25.8497 56.2817C27.3149 57.7902 29.0877 59.5341 30.7971 61.186C32.5074 62.8387 34.1585 64.4035 35.3816 65.5547C35.9933 66.1305 36.4982 66.603 36.8503 66.9318C37.0264 67.0961 37.1642 67.2245 37.2582 67.3119L37.4017 67.4454L37.4022 67.4458C37.4023 67.4459 37.4024 67.446 37.9995 66.8023ZM37.9995 66.8023L38.5968 67.4458L37.9995 68L37.4022 67.4458L37.9995 66.8023ZM20.4396 42.4379C20.4396 32.7398 28.3014 24.878 37.9995 24.878C47.6976 24.878 55.5595 32.7398 55.5595 42.4379C55.5595 46.0555 54.4655 49.4177 52.5904 52.2115C50.7152 55.0053 37.9995 66.8023 37.9995 66.8023C37.9995 66.8023 25.2838 55.0053 23.4087 52.2115C21.5335 49.4177 20.4396 46.0555 20.4396 42.4379Z"
        fill="white"
      />
      <path
        d="M46.3405 51.2179H44.2127V37.2518L39.1368 51.2179H36.8601L31.7842 37.2518V51.2179H29.6586V34.536H32.9098L38.0122 48.5974L43.1113 34.536H46.3405V51.2179Z"
        fill="white"
      />
    </svg>
  );
};

export default MedeLogo;
