import React from "react";
import AlertIcon from "../icons/AlertIcon";

interface ErrorComponentProps {
  error: string;
}

const ErrorComponent: React.FC<ErrorComponentProps> = ({ error }) => {
  return (
    <div className="flex flex-col items-center text-center justify-center gap-2 w-full h-[300px]">
      <AlertIcon />
      <div className="text-error-500 bold">{error}</div>
    </div>
  );
};

export default ErrorComponent;
