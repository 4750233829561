import React from "react";

const MessageIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86108)">
        <g clipPath="url(#clip1_10429_86108)">
          <path
            d="M19 28.125V29.1252L19.8001 28.525L23.6321 25.65L23.6321 25.65L23.6355 25.6474C23.7595 25.5527 23.9174 25.5 24.082 25.5H29.5C30.4675 25.5 31.25 24.7175 31.25 23.75V12.5C31.25 11.5325 30.4675 10.75 29.5 10.75H14.5C13.5325 10.75 12.75 11.5325 12.75 12.5V23.75C12.75 24.7175 13.5325 25.5 14.5 25.5H18.25C18.6653 25.5 19 25.8347 19 26.25V28.125ZM24.082 25.75H23.9153L23.782 25.85L18.95 29.475C18.95 29.475 18.95 29.475 18.9499 29.475C18.9152 29.5011 18.8627 29.5086 18.8141 29.4848C18.7765 29.4663 18.75 29.4294 18.75 29.375V27.5V26.25V25.75H18.25H17H14.5C13.3972 25.75 12.5 24.8528 12.5 23.75V12.5C12.5 11.3972 13.3972 10.5 14.5 10.5H29.5C30.6028 10.5 31.5 11.3972 31.5 12.5V23.75C31.5 24.8528 30.6028 25.75 29.5 25.75H24.082Z"
            fill="#081521"
            stroke="#081521"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10429_86108">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
        <clipPath id="clip1_10429_86108">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default MessageIcon;
