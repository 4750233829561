import React from "react";

const RepeatIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86156)">
        <path
          d="M25.3468 10.5392C25.3919 10.4895 25.4741 10.4858 25.5233 10.5304L28.9605 13.6551C28.9846 13.677 29 13.7104 29 13.7461C29 13.7819 28.9846 13.8152 28.9605 13.8371L25.5233 16.9618C25.4704 17.0098 25.3907 17.0022 25.3493 16.9559C25.2965 16.8968 25.3079 16.8192 25.3527 16.779L25.3528 16.779L25.3552 16.7768L27.5974 14.7456L28.5584 13.875H27.2617H18.8867C15.6244 13.875 12.9319 16.4353 12.7701 19.6938C12.7701 19.6939 12.7701 19.6939 12.7701 19.694L12.7545 20.0063L12.7544 20.0086C12.7516 20.0698 12.6956 20.1274 12.6232 20.1256C12.5532 20.1202 12.5007 20.0597 12.5033 19.9922C12.5034 19.9919 12.5034 19.9917 12.5034 19.9915L12.5189 19.6813C12.5189 19.6813 12.5189 19.6813 12.5189 19.6812C12.6892 16.2872 15.489 13.625 18.8867 13.625H27.2617H28.5547L27.5981 12.7551L25.3559 10.716L25.3556 10.7157C25.3059 10.6706 25.3022 10.5884 25.3468 10.5392ZM18.661 29.4608C18.6159 29.5105 18.5338 29.5143 18.4846 29.4698C18.4846 29.4697 18.4845 29.4697 18.4845 29.4696L15.0473 26.3449C15.0232 26.323 15.0078 26.2897 15.0078 26.2539C15.0078 26.2182 15.0232 26.1848 15.0473 26.163L18.4845 23.0382C18.4845 23.0382 18.4846 23.0381 18.4846 23.0381C18.5375 22.9902 18.6171 22.9979 18.6585 23.0442C18.7113 23.1033 18.6999 23.1809 18.655 23.221L18.655 23.221L18.6526 23.2232L16.4104 25.2545L15.4494 26.125H16.7461H25.1211C28.3834 26.125 31.0759 23.5647 31.2376 20.3062C31.2376 20.3062 31.2376 20.3061 31.2377 20.306L31.2533 19.9937L31.2534 19.9915C31.2562 19.9295 31.3136 19.8712 31.3874 19.8745C31.4493 19.8773 31.5074 19.9344 31.5044 20.0079C31.5044 20.0081 31.5044 20.0084 31.5044 20.0086L31.4889 20.3187C31.3186 23.7128 28.5188 26.375 25.1211 26.375H16.7461H15.4531L16.4097 27.2449L18.6519 29.284L18.6522 29.2843C18.7019 29.3294 18.7056 29.4117 18.661 29.4608Z"
          fill="#081521"
          stroke="#081521"
        />
      </g>
      <defs>
        <clipPath id="clip0_10429_86156">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default RepeatIcon;
