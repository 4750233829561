import { useEffect, useState } from "react";

import ChartAdapter from "./components/ChartAdapter";
import SearchInput from "./components/SearchInput";
import Widget from "./components/Widget";
import Avatar from "./icons/Avatar";
import BellIcon from "./icons/BellIcon";
import BullseyeIcon from "./icons/BullseyeIcon";
import ChartIcon from "./icons/ChartIcon";
import CollapseButton from "./icons/CollapseButton";
import GougeIcon from "./icons/GougeIcon";
import HelpIcon from "./icons/HelpIcon";
import LightbulbIcon from "./icons/LightbulbIcon";
import MedeLogo from "./icons/MedeLogo";
import MessageIcon from "./icons/MessageIcon";
import RepeatIcon from "./icons/RepeatIcon";
import SearchIcon from "./icons/SearchIcon";
import StarIcon from "./icons/StarIcon";
import WrenchIcon from "./icons/WrenchIcon";

const API_URL = "https://api.mededemo.spaceinch.tech";

function App() {
  const [responses, setResponses] = useState<any[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    handleRefresh();
  }, []);

  const handleSearch = async (query: string) => {
    setIsLoading(true);
    setErrorMessage("");

    try {
      // Make a request to the backend with the query
      await fetch(`${API_URL}/search?q=${query}`);
    } catch (error) {
      console.error(error);

      setErrorMessage(
        "An error occurred while fetching the data. Please try with a different query."
      );
    }

    await handleRefresh();

    setIsLoading(false);
    setSearchValue("");
  };

  const onSearchChange = (val: string) => {
    setSearchValue(val);
  };

  const handleRefresh = async () => {
    try {
      const apiResponses = await (
        await fetch(`${API_URL}/all-responses`)
      ).json();
      setResponses(apiResponses);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        "An error occurred while fetching the data. Please try again."
      );
    }
  };

  const handleDelete = async (id: string) => {
    setIsLoading(true);
    await fetch(`${API_URL}/response?alias_id=${id}`, {
      method: "DELETE",
    });
    await handleRefresh();
    setIsLoading(false);
  };

  return (
    <div className="flex row w-full">
      <nav className="bg-white h-screen w-20 flex flex-col items-center border-r sticky top-0">
        <div>
          <MedeLogo />
          <CollapseButton />
          <SearchIcon />
        </div>
        <div className="p-4 gap-2 flex flex-col justify-start align-center flex-grow">
          <LightbulbIcon />
          <StarIcon />
          <BellIcon />
          <MessageIcon />
          <WrenchIcon />
        </div>
        <div className="p-4 gap-2 flex flex-col justify-start align-center border-t border-neutral-100">
          <GougeIcon />
          <ChartIcon />
          <RepeatIcon />
          <BullseyeIcon />
        </div>
        <div className="p-4 gap-2 flex flex-col justify-start align-center border-t border-neutral-100">
          <HelpIcon />
        </div>
        <div className="p-4 gap-2 flex flex-col justify-start align-center border-t border-neutral-100">
          <Avatar />
        </div>
      </nav>
      <div className="min-h-screen w-full flex flex-col">
        <header className="px-10 py-8">
          <h2 className="text-display-sm font-bold text-black mb-1">
            MedeAI: Data insights from your prompts
          </h2>
          <p className="text-text-md font-normal mb-6 text-neutral-600">
            Transform your data into meaningful insights with a few words.
          </p>
          <SearchInput
            createButtonOnClick={handleSearch}
            disabled={isLoading}
            value={searchValue}
            onSearchChange={onSearchChange}
          />
          {errorMessage && (
            <p className="text-red-500 text-sm my-2">{errorMessage}</p>
          )}
          <button
            className="flex items-center px-6 py-2 bg-grapeCrush-500 text-white rounded-lg hover:bg-blue-700"
            disabled={isLoading}
            style={{
              cursor: isLoading ? "not-allowed" : "pointer",
              opacity: isLoading ? "50%" : "100%",
            }}
            onClick={handleRefresh}
          >
            {/* Refresh Icon */}
            <svg
              fill="#ffffff"
              height="12px"
              width="12px"
              version="1.1"
              id="Capa_1"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 489.645 489.645"
              xmlSpace="preserve"
              className="mr-2"
            >
              <g>
                <path
                  d="M460.656,132.911c-58.7-122.1-212.2-166.5-331.8-104.1c-9.4,5.2-13.5,16.6-8.3,27c5.2,9.4,16.6,13.5,27,8.3
		c99.9-52,227.4-14.9,276.7,86.3c65.4,134.3-19,236.7-87.4,274.6c-93.1,51.7-211.2,17.4-267.6-70.7l69.3,14.5
		c10.4,2.1,21.8-4.2,23.9-15.6c2.1-10.4-4.2-21.8-15.6-23.9l-122.8-25c-20.6-2-25,16.6-23.9,22.9l15.6,123.8
		c1,10.4,9.4,17.7,19.8,17.7c12.8,0,20.8-12.5,19.8-23.9l-6-50.5c57.4,70.8,170.3,131.2,307.4,68.2
		C414.856,432.511,548.256,314.811,460.656,132.911z"
                />
              </g>
            </svg>
            {/* Refresh Text */}
            Refresh
          </button>
        </header>
        <main className="h-fit px-10 py-8 bg-neutral-50 border-t border-b border-neutral-200 flex-grow">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {responses.map((response) => (
              <Widget
                title={response.chart_title}
                description={response.chart_description}
                query={response.database_query}
                disabled={isLoading}
                key={response.id}
                onDelete={() => handleDelete(response.alias_id)}
              >
                <ChartAdapter
                  chartType={response.chart_type}
                  chartData={response.chart_data}
                  additional={response.additional}
                  error={response.error}
                />
              </Widget>
            ))}
          </div>
        </main>
        <footer className="px-10 py-8 flex justify-center items-center text-black">
          © 2024 MedeAnalytics, Inc.
        </footer>
      </div>
    </div>
  );
}

export default App;
