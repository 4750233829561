import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface BarChartComponentProps {
  data: { name: string; value: number }[]; // Expected data format
  valueLabel: string;
  valueKey?: string;
  nameKey?: string;
}

const barColor = "#79B0E0";

const BarChartComponent: React.FC<BarChartComponentProps> = ({
  data,
  valueLabel,
  valueKey = "value",
  nameKey = "name",
}) => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex w-full max-w-6xl bg-white">
        <div className="flex-1">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={nameKey} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                name={valueLabel}
                dataKey={valueKey}
                // barSize={30}
                fill={barColor}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default BarChartComponent;
