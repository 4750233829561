import React from "react";

interface HeatmapChartProps {
  data: Array<any>;
  rowTitleKey: string;
  columnTitleKey: string;
  valueKey: string;
  minValue?: number;
  maxValue?: number;
}

/**
 * HeatmapChartComponent - A heatmap chart using HTML table and Tailwind CSS.
 * @param {Object} props - Props for the component.
 * @param {Array} props.data - Array of data objects.
 * @param {string} props.rowTitleKey - Key for row titles in data objects.
 * @param {string} props.columnTitleKey - Key for column titles in data objects.
 * @param {string} props.valueKey - Key for cell values in data objects.
 * @param {number} props.minValue - Minimum value for heatmap color scaling.
 * @param {number} props.maxValue - Maximum value for heatmap color scaling.
 */
const HeatmapChartComponent: React.FC<HeatmapChartProps> = ({
  data,
  rowTitleKey,
  columnTitleKey,
  valueKey,
  minValue = -1,
  maxValue = 1,
}) => {
  // Transform data into a matrix format with unique row and column titles
  const transformData = (
    data: Array<any>,
    rowTitleKey: string,
    columnTitleKey: string,
    valueKey: string
  ) => {
    const titlesSet = new Set<string>([
      ...data.map((item) => item[rowTitleKey]),
      ...data.map((item) => item[columnTitleKey]),
    ]);

    const titles: string[] = [];
    titlesSet.forEach((title) => {
      titles.push(title);
    });
    titles.sort();

    const dataObj: Record<string, Record<string, number>> = {};
    data.forEach((item) => {
      if (!dataObj[item[rowTitleKey]]) {
        dataObj[item[rowTitleKey]] = {};
      }
      dataObj[item[rowTitleKey]][item[columnTitleKey]] = item[valueKey];
    });

    const matrix = titles.map((rowTitle) =>
      titles.map((columnTitle) => {
        if (!dataObj[rowTitle] || !dataObj[rowTitle][columnTitle]) {
          if (rowTitle === columnTitle) return 1;
          else return dataObj[columnTitle] ? dataObj[columnTitle][rowTitle] : 0;
        }
        return dataObj[rowTitle][columnTitle];
      })
    );
    return { titles, matrix };
  };

  const { titles, matrix } = transformData(
    data,
    rowTitleKey,
    columnTitleKey,
    valueKey
  );

  // Generate a color based on a value within the min-max range
  const getCellColor = (value: number) => {
    if (value === null) return "#F2F2F2"; // Default color for empty cells
    const normalized = (value - minValue) / (maxValue - minValue);
    const intensity = Math.min(Math.max(normalized, 0), 1); // Clamp between 0 and 1
    const red = Math.round(255 * (1 - intensity));
    const green = Math.round(255 * intensity);
    return `rgb(${red},${green},150)`;
  };

  return (
    <div className="overflow-auto max-w-full">
      <table className="table-auto border-separate relative">
        <thead>
          <tr>
            <th className="border border-neutral-300 sticky top-0 left-0 bg-white z-20"></th>
            {titles.map((title, index) => (
              <th
                key={index}
                className="border border-neutral-300 px-2 py-2 align-text-bottom text-sm font-medium sticky top-0 bg-white"
              >
                <div
                  className="rotate-[-180deg] whitespace-nowrap"
                  style={{ writingMode: "vertical-lr" }}
                >
                  {title}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {titles.map((rowTitle, rowIndex) => (
            <tr
              key={rowIndex}
              className="h-[57px] border border-separate border-neutral-300"
            >
              <td className="border border-neutral-300 px-2 py-2 text-sm font-medium sticky left-0 bg-white z-10">
                {rowTitle}
              </td>
              {matrix[rowIndex].map((value: number, colIndex) => (
                <td
                  key={colIndex}
                  className={`border border-neutral-300 px-2 py-1`}
                  style={{ backgroundColor: getCellColor(value) }}
                >
                  {value !== null ? value?.toFixed(3) : ""}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default HeatmapChartComponent;
