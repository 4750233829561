import React from "react";

const LightbulbIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86105)">
        <path
          d="M25.2528 23.2901C25.5602 22.726 25.9444 22.1645 26.3294 21.6284V21.677L27.2399 20.3675C27.9289 19.3765 28.3333 18.1759 28.3333 16.875C28.3333 13.4934 25.5899 10.75 22.2083 10.75C18.8267 10.75 16.0833 13.4934 16.0833 16.875C16.0833 18.1759 16.4877 19.3765 17.1767 20.3675C17.2869 20.526 17.4097 20.696 17.5341 20.8684C17.5934 20.9505 17.6531 21.0332 17.712 21.1154L17.712 21.1154L17.7138 21.1179L17.7472 21.164C18.2386 21.8409 18.7658 22.5673 19.1643 23.2949L19.1646 23.2954C19.3782 23.6843 19.5266 24.0915 19.6283 24.5039H19.3677C19.2716 24.1329 19.135 23.7662 18.944 23.4165L18.9433 23.4153C18.5413 22.6844 18.0452 22.0028 17.5709 21.3511L17.5618 21.3386L17.5609 21.3374C17.3572 21.0593 17.158 20.7871 16.9669 20.512C16.2532 19.4794 15.8333 18.2266 15.8333 16.875C15.8333 13.3543 18.6876 10.5 22.2083 10.5C25.729 10.5 28.5833 13.3543 28.5833 16.875C28.5833 18.2269 28.1632 19.4791 27.4466 20.507L27.4461 20.5078C27.2549 20.783 27.0556 21.0553 26.8518 21.3335L26.8518 21.3335C26.3739 21.9862 25.8738 22.672 25.4689 23.4124L25.4687 23.4126C25.2777 23.7623 25.1411 24.129 25.045 24.5H24.7884C24.89 24.0876 25.0384 23.6804 25.252 23.2915L25.2528 23.2901ZM20.4388 27H19.7308L19.9676 27.6672C20.2938 28.5865 21.1731 29.25 22.2083 29.25C23.2435 29.25 24.1229 28.5865 24.4491 27.6672L24.6858 27H23.9778H20.4388ZM22.2083 13.25C20.2056 13.25 18.5833 14.8723 18.5833 16.875C18.5833 16.9426 18.5259 17 18.4583 17C18.3907 17 18.3333 16.9426 18.3333 16.875C18.3333 14.7332 20.0665 13 22.2083 13C22.2759 13 22.3333 13.0574 22.3333 13.125C22.3333 13.1926 22.2759 13.25 22.2083 13.25ZM19.5833 26.875V26.75H24.8333V26.875C24.8333 28.3254 23.6587 29.5 22.2083 29.5C20.7579 29.5 19.5833 28.3254 19.5833 26.875Z"
          fill="#081521"
          stroke="#081521"
        />
      </g>
      <defs>
        <clipPath id="clip0_10429_86105">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default LightbulbIcon;
