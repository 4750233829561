import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

interface TopChartComponentProps {
  data: { name: string; value: number }[]; // Expected data format
  valueLabel: string;
  valueKey?: string;
  nameKey?: string;
}

const barColor = "#29CCC1";

const TopChartComponent: React.FC<TopChartComponentProps> = ({
  data,
  valueLabel,
  valueKey = "value",
  nameKey = "name",
}) => {
  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex w-full max-w-6xl bg-white rounded-lg">
        <div className="flex-1">
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={data} layout="vertical" barCategoryGap="10%">
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey={valueKey} type="number" />
              <YAxis
                dataKey={nameKey}
                type="category"
                fontSize={8}
                tickCount={1000}
              />
              <Tooltip />
              <Legend />
              <Bar
                name={valueLabel}
                dataKey={valueKey}
                fill={barColor}
                // barSize={30}
                layout="vertical"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    </div>
  );
};

export default TopChartComponent;
