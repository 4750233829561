import React from "react";

const SearchIcon = () => {
  return (
    <svg
      width="76"
      height="88"
      viewBox="0 0 76 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_10429_70028" fill="white">
        <path d="M0 0H75.999V88H0V0Z" />
      </mask>
      <path
        d="M75.999 87H0V89H75.999V87Z"
        fill="#F2F2F2"
        mask="url(#path-1-inside-1_10429_70028)"
      />
      <rect x="16" y="24" width="43.999" height="40" rx="8" fill="white" />
      <g clipPath="url(#clip0_10429_70028)">
        <path
          d="M42.9995 42.125C42.9995 41.2222 42.8217 40.3282 42.4762 39.4941C42.1307 38.6599 41.6243 37.902 40.9859 37.2636C40.3475 36.6252 39.5896 36.1188 38.7555 35.7733C37.9213 35.4278 37.0273 35.25 36.1245 35.25C35.2217 35.25 34.3277 35.4278 33.4936 35.7733C32.6595 36.1188 31.9016 36.6252 31.2632 37.2636C30.6247 37.902 30.1183 38.6599 29.7728 39.4941C29.4273 40.3282 29.2495 41.2222 29.2495 42.125C29.2495 43.0278 29.4273 43.9218 29.7728 44.7559C30.1183 45.5901 30.6247 46.348 31.2632 46.9864C31.9016 47.6248 32.6595 48.1312 33.4936 48.4767C34.3277 48.8222 35.2217 49 36.1245 49C37.0273 49 37.9213 48.8222 38.7555 48.4767C39.5896 48.1312 40.3475 47.6248 40.9859 46.9864C41.6243 46.348 42.1307 45.5901 42.4762 44.7559C42.8217 43.9218 42.9995 43.0278 42.9995 42.125ZM41.4097 48.2969C39.9917 49.5156 38.144 50.25 36.1245 50.25C31.6362 50.25 27.9995 46.6133 27.9995 42.125C27.9995 37.6367 31.6362 34 36.1245 34C40.6128 34 44.2495 37.6367 44.2495 42.125C44.2495 44.1445 43.5151 45.9922 42.2964 47.4102L47.8159 52.9336C48.0581 53.1758 48.0581 53.5742 47.8159 53.8164C47.5737 54.0586 47.1753 54.0586 46.9331 53.8164L41.4097 48.2969Z"
          fill="#0C2032"
        />
      </g>
      <defs>
        <clipPath id="clip0_10429_70028">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(27.9995 34)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SearchIcon;
