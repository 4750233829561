import React, { useState } from "react";

interface WidgetProps {
  title: string;
  description: string;
  disabled?: boolean;
  onDelete?: () => void; // The delete function (property)
  children: React.ReactNode; // The child component (property)
  query?: string; 
}

const Widget: React.FC<WidgetProps> = ({
  title,
  description,
  children,
  disabled = false,
  onDelete = () => {},
  query = "",
}) => {
  const [showQuery, setShowQuery] = useState<boolean>(false);
  const [fullScreen, setFullScreen] = useState<boolean>(false);
  return (
    <div
      className="h-fit"
      style={
        fullScreen
          ? {
              width: "100%",
              height: "100%",
              position: "fixed",
              top: 0,
              left: 0,
              zIndex: 1000,
              padding: "1rem",
              backgroundColor: "rgba(0,0,0,0.5)",
            }
          : {
              cursor: disabled ? "not-allowed" : "default",
              opacity: disabled ? "50%" : "100%",
            }
      }
    >
      {/* First part: White background, title, and child component */}
      <div className="bg-white p-6 border rounded-t-[15px]">
        {/* Title and delete button */}
        <div className="flex justify-between items-center align-center mb-4">
          <h2 className="text-text-md font-semibold text-black">{title}</h2>
          <div>
            {true && (
              <button
                className="hover:bg-neutral-200 rounded-full  mr-4"
                onClick={() => setFullScreen(!fullScreen)}
              >
                <svg
                  className="fill-neutral-500 hover:fill-neutral-700 active:fill-neutral-800 transition-all duration-250"
                  width="20"
                  height="20"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 384.97 384.97"
                >
                  <g>
                    <g id="Fullscreen_1_">
                      <path
                        d="M372.939,216.545c-6.123,0-12.03,5.269-12.03,12.03v132.333H24.061V24.061h132.333c6.388,0,12.03-5.642,12.03-12.03
			S162.409,0,156.394,0H24.061C10.767,0,0,10.767,0,24.061v336.848c0,13.293,10.767,24.061,24.061,24.061h336.848
			c13.293,0,24.061-10.767,24.061-24.061V228.395C384.97,221.731,380.085,216.545,372.939,216.545z"
                      />
                      <path
                        d="M372.939,0H252.636c-6.641,0-12.03,5.39-12.03,12.03s5.39,12.03,12.03,12.03h91.382L99.635,268.432
			c-4.668,4.668-4.668,12.235,0,16.903c4.668,4.668,12.235,4.668,16.891,0L360.909,40.951v91.382c0,6.641,5.39,12.03,12.03,12.03
			s12.03-5.39,12.03-12.03V12.03l0,0C384.97,5.558,379.412,0,372.939,0z"
                      />
                    </g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                    <g></g>
                  </g>
                </svg>
              </button>
            )}
            {true && (
              <button
                className="hover:bg-neutral-200 rounded-full mr-4"
                onClick={() => setShowQuery(!showQuery)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  x="0px"
                  y="0px"
                  width="20"
                  height="20"
                  viewBox="0 0 50 50"
                >
                  <path d="M 25 2 C 12.309295 2 2 12.309295 2 25 C 2 37.690705 12.309295 48 25 48 C 37.690705 48 48 37.690705 48 25 C 48 12.309295 37.690705 2 25 2 z M 25 4 C 36.609824 4 46 13.390176 46 25 C 46 36.609824 36.609824 46 25 46 C 13.390176 46 4 36.609824 4 25 C 4 13.390176 13.390176 4 25 4 z M 25 11 A 3 3 0 0 0 22 14 A 3 3 0 0 0 25 17 A 3 3 0 0 0 28 14 A 3 3 0 0 0 25 11 z M 21 21 L 21 23 L 22 23 L 23 23 L 23 36 L 22 36 L 21 36 L 21 38 L 22 38 L 23 38 L 27 38 L 28 38 L 29 38 L 29 36 L 28 36 L 27 36 L 27 21 L 26 21 L 22 21 L 21 21 z"></path>
                </svg>
              </button>
            )}

            <button onClick={onDelete}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="fill-neutral-500 hover:fill-neutral-700 active:fill-neutral-800 transition-all duration-250 "
              >
                <path d="M9.69687 1.85156L8.97969 3H16.0203L15.3031 1.85156C15.1672 1.63125 14.9234 1.5 14.6656 1.5H10.3297C10.0719 1.5 9.83281 1.63125 9.69219 1.85156H9.69687ZM16.5781 1.05938L17.7922 3H20H21.5H22.25C22.6625 3 23 3.3375 23 3.75C23 4.1625 22.6625 4.5 22.25 4.5H21.5V20.25C21.5 22.3219 19.8219 24 17.75 24H7.25C5.17812 24 3.5 22.3219 3.5 20.25V4.5H2.75C2.3375 4.5 2 4.1625 2 3.75C2 3.3375 2.3375 3 2.75 3H3.5H5H7.20781L8.42188 1.05938C8.83437 0.398438 9.55625 0 10.3297 0H14.6656C15.4438 0 16.1609 0.398438 16.5734 1.05938H16.5781ZM5 4.5V20.25C5 21.4922 6.00781 22.5 7.25 22.5H17.75C18.9922 22.5 20 21.4922 20 20.25V4.5H5ZM8.75 8.25V18.75C8.75 19.1625 8.4125 19.5 8 19.5C7.5875 19.5 7.25 19.1625 7.25 18.75V8.25C7.25 7.8375 7.5875 7.5 8 7.5C8.4125 7.5 8.75 7.8375 8.75 8.25ZM13.25 8.25V18.75C13.25 19.1625 12.9125 19.5 12.5 19.5C12.0875 19.5 11.75 19.1625 11.75 18.75V8.25C11.75 7.8375 12.0875 7.5 12.5 7.5C12.9125 7.5 13.25 7.8375 13.25 8.25ZM17.75 8.25V18.75C17.75 19.1625 17.4125 19.5 17 19.5C16.5875 19.5 16.25 19.1625 16.25 18.75V8.25C16.25 7.8375 16.5875 7.5 17 7.5C17.4125 7.5 17.75 7.8375 17.75 8.25Z" />
              </svg>
            </button>
          </div>
        </div>
        {/* Children elements */}
        <div>{children}</div>
      </div>

      {/* Second part: Top border, padding, and description */}
      <div
        className={
          showQuery
            ? "border border-neutral-200 p-6 bg-gray-100"
            : "border border-neutral-200 p-6 rounded-b-[15px] bg-gray-100"
        }
      >
        <p className="text-text-sm text-black">{description}</p>
      </div>

      {showQuery && (
        <div className="border border-neutral-200 p-6 rounded-b-[15px] bg-gray-200">
          <pre className="text-xs text-black overflow-x-auto whitespace-pre-wrap">
            <code className="block">{query}</code>
          </pre>
        </div>
      )}
    </div>
  );
};

export default Widget;
