import React from "react";
import {
  PieChart,
  Pie,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

interface PieChartProps {
  data: { name: string; value: number }[];
  maxCategories?: number;
  nameKey?: string;
  valueKey?: string;
}
// Define your custom colors based on the brand colors.
const brandColors = [
  "#9387DE",
  "#4B95A6",
  "#29CCC1",
  "#F79764",
  "#79B0E0",
  "#6D5FCF",
  "#006B84",
  "#00BFB3",
  "#F47734",
  "#287CB8",
];

const PieChartComponent: React.FC<PieChartProps> = ({
  data,
  maxCategories = 9,
  nameKey = "name",
  valueKey = "value",
}) => {
  if (data.length > maxCategories) {
    let slicedData = data
      .sort((a, b) => b.value - a.value)
      .slice(0, maxCategories);
    const others = {
      name: "Others",
      value: data
        .slice(maxCategories)
        .reduce((acc, curr) => acc + curr.value, 0),
    };
    data = [...slicedData, others];
  }
  return (
    <div className="flex justify-center items-center w-full">
      <div className="flex-1">
        <ResponsiveContainer width="100%" height={300}>
          <PieChart>
            <Pie
              data={data}
              dataKey={valueKey}
              nameKey={nameKey}
              outerRadius="70%"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={brandColors[index % brandColors.length]}
                />
              ))}
            </Pie>
            <Tooltip />
            <Legend layout="vertical" align="right" verticalAlign="middle" />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default PieChartComponent;
