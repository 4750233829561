import React from "react";

const BellIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86107)">
        <path
          d="M21.1232 10.625C21.1232 10.2812 21.4044 10 21.748 10C22.0917 10 22.3729 10.2812 22.3729 10.625V11.2812C25.5324 11.5938 27.9967 14.2578 27.9967 17.5V18.6367C27.9967 20.3438 28.6762 21.9805 29.8829 23.1914L29.9923 23.3008C30.3164 23.625 30.5 24.0664 30.5 24.5234C30.5 25.4805 29.7267 26.2539 28.7699 26.2539H14.7301C13.7733 26.25 13 25.4766 13 24.5195C13 24.0625 13.1836 23.6211 13.5077 23.2969L13.617 23.1875C14.8199 21.9805 15.4994 20.3438 15.4994 18.6367V17.5C15.4994 14.2578 17.9637 11.5938 21.1232 11.2812V10.625ZM21.748 12.5C18.9869 12.5 16.7492 14.7383 16.7492 17.5V18.6367C16.7492 20.6758 15.9407 22.6328 14.4958 24.0742L14.3903 24.1797C14.3005 24.2695 14.2497 24.3906 14.2497 24.5195C14.2497 24.7852 14.4645 25 14.7301 25H28.766C29.0316 25 29.2464 24.7852 29.2464 24.5195C29.2464 24.3906 29.1956 24.2695 29.1058 24.1797L28.9964 24.0703C27.5553 22.6289 26.743 20.6719 26.743 18.6328V17.5C26.743 14.7383 24.5052 12.5 21.7441 12.5H21.748ZM20.5686 27.918C20.7405 28.4023 21.2052 28.75 21.748 28.75C22.2909 28.75 22.7556 28.4023 22.9275 27.918C23.0407 27.5938 23.4 27.4219 23.7242 27.5352C24.0483 27.6484 24.2202 28.0078 24.1069 28.332C23.7632 29.3047 22.8376 30 21.748 30C20.6584 30 19.7329 29.3047 19.3892 28.332C19.2759 28.0078 19.4439 27.6484 19.7719 27.5352C20.1 27.4219 20.4554 27.5898 20.5686 27.918Z"
          fill="#0C2032"
        />
        <rect
          x="24.25"
          y="3.25"
          width="17.5"
          height="17.5"
          rx="8.75"
          fill="#D0464B"
        />
        <rect
          x="24.25"
          y="3.25"
          width="17.5"
          height="17.5"
          rx="8.75"
          stroke="white"
          strokeWidth="1.5"
        />
        <path
          d="M30.863 8.22727V15.5H29.7621V9.32812H29.7195L27.9795 10.4645V9.41335L29.7941 8.22727H30.863ZM35.3723 15.6207C34.8112 15.6184 34.3318 15.4704 33.9341 15.1768C33.5363 14.8833 33.2321 14.456 33.0214 13.8949C32.8107 13.3338 32.7054 12.6579 32.7054 11.8672C32.7054 11.0788 32.8107 10.4053 33.0214 9.84659C33.2345 9.28788 33.5399 8.86174 33.9376 8.56818C34.3377 8.27462 34.8159 8.12784 35.3723 8.12784C35.9286 8.12784 36.4057 8.2758 36.8034 8.57173C37.2011 8.86529 37.5053 9.29143 37.716 9.85014C37.9291 10.4065 38.0356 11.0788 38.0356 11.8672C38.0356 12.6603 37.9303 13.3374 37.7196 13.8984C37.5089 14.4571 37.2047 14.8845 36.8069 15.1804C36.4092 15.474 35.931 15.6207 35.3723 15.6207ZM35.3723 14.6726C35.8647 14.6726 36.2494 14.4323 36.5264 13.9517C36.8058 13.4711 36.9454 12.7763 36.9454 11.8672C36.9454 11.2635 36.8815 10.7533 36.7537 10.3366C36.6282 9.91761 36.4471 9.60038 36.2103 9.38494C35.976 9.16714 35.6966 9.05824 35.3723 9.05824C34.8822 9.05824 34.4975 9.29972 34.2182 9.78267C33.9388 10.2656 33.7979 10.9605 33.7956 11.8672C33.7956 12.4732 33.8583 12.9858 33.9838 13.4048C34.1116 13.8215 34.2927 14.1375 34.5271 14.353C34.7615 14.5661 35.0432 14.6726 35.3723 14.6726Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_10429_86107">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BellIcon;
