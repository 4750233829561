import React from "react";

const HelpIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86273)">
        <g clipPath="url(#clip1_10429_86273)">
          <path
            d="M19.1618 16.9314C19.0873 16.9112 19.0543 16.8402 19.0681 16.7849L19.0745 16.7624L19.0626 16.7577L19.0733 16.7605C19.2695 16.0179 19.939 15.5 20.707 15.5H22.9375C24.044 15.5 24.9375 16.4049 24.9375 17.5117C24.9375 18.2631 24.5179 18.957 23.8474 19.3005C23.8471 19.3007 23.8468 19.3008 23.8465 19.301L22.399 20.0345L22.125 20.1733V20.4805V21.25C22.125 21.3176 22.0676 21.375 22 21.375C21.9324 21.375 21.875 21.3176 21.875 21.25V20.0977C21.875 20.054 21.9003 20.0075 21.9465 19.9822L23.7292 19.0714C23.7296 19.0712 23.73 19.071 23.7304 19.0708C24.3194 18.7729 24.6875 18.1611 24.6875 17.5039C24.6875 16.5365 23.9052 15.7422 22.9375 15.7422H20.707C20.0577 15.7422 19.4837 16.1804 19.3184 16.8097L19.3122 16.8314L19.3121 16.8313L19.3089 16.8435C19.2918 16.9096 19.2217 16.9476 19.1618 16.9314ZM31.25 20C31.25 17.5467 30.2754 15.194 28.5407 13.4593C26.806 11.7246 24.4533 10.75 22 10.75C19.5467 10.75 17.194 11.7246 15.4593 13.4593C13.7246 15.194 12.75 17.5467 12.75 20C12.75 22.4533 13.7246 24.806 15.4593 26.5407C17.194 28.2754 19.5467 29.25 22 29.25C24.4533 29.25 26.806 28.2754 28.5407 26.5407C30.2754 24.806 31.25 22.4533 31.25 20ZM12.5 20C12.5 17.4804 13.5009 15.0641 15.2825 13.2825C17.0641 11.5009 19.4804 10.5 22 10.5C24.5196 10.5 26.9359 11.5009 28.7175 13.2825C30.4991 15.0641 31.5 17.4804 31.5 20C31.5 22.5196 30.4991 24.9359 28.7175 26.7175C26.9359 28.4991 24.5196 29.5 22 29.5C19.4804 29.5 17.0641 28.4991 15.2825 26.7175C13.5009 24.9359 12.5 22.5196 12.5 20ZM21.5625 23.75C21.5625 23.634 21.6086 23.5227 21.6906 23.4406C21.7727 23.3586 21.884 23.3125 22 23.3125C22.116 23.3125 22.2273 23.3586 22.3094 23.4406C22.3914 23.5227 22.4375 23.634 22.4375 23.75C22.4375 23.866 22.3914 23.9773 22.3094 24.0594C22.2273 24.1414 22.116 24.1875 22 24.1875C21.884 24.1875 21.7727 24.1414 21.6906 24.0594C21.6086 23.9773 21.5625 23.866 21.5625 23.75Z"
            fill="#081521"
            stroke="#081521"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_10429_86273">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
        <clipPath id="clip1_10429_86273">
          <rect
            width="20"
            height="20"
            fill="white"
            transform="translate(12 10)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default HelpIcon;
