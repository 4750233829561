import React from "react";

const Avatar = () => {
  return (
    <svg
      width="44"
      height="56"
      viewBox="0 0 44 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="1.99951" y="8" width="40" height="40" rx="20" fill="#EDEBF9" />
      <path
        d="M26.9995 22C26.9995 21.4091 26.8831 20.8239 26.657 20.2779C26.4308 19.732 26.0994 19.2359 25.6815 18.818C25.2636 18.4002 24.7676 18.0687 24.2216 17.8425C23.6756 17.6164 23.0905 17.5 22.4995 17.5C21.9086 17.5 21.3234 17.6164 20.7774 17.8425C20.2315 18.0687 19.7354 18.4002 19.3175 18.818C18.8997 19.2359 18.5682 19.732 18.3421 20.2779C18.1159 20.8239 17.9995 21.4091 17.9995 22C17.9995 22.5909 18.1159 23.1761 18.3421 23.7221C18.5682 24.268 18.8997 24.7641 19.3175 25.182C19.7354 25.5998 20.2315 25.9313 20.7774 26.1575C21.3234 26.3836 21.9086 26.5 22.4995 26.5C23.0905 26.5 23.6756 26.3836 24.2216 26.1575C24.7676 25.9313 25.2636 25.5998 25.6815 25.182C26.0994 24.7641 26.4308 24.268 26.657 23.7221C26.8831 23.1761 26.9995 22.5909 26.9995 22ZM16.4995 22C16.4995 20.4087 17.1317 18.8826 18.2569 17.7574C19.3821 16.6321 20.9082 16 22.4995 16C24.0908 16 25.6169 16.6321 26.7422 17.7574C27.8674 18.8826 28.4995 20.4087 28.4995 22C28.4995 23.5913 27.8674 25.1174 26.7422 26.2426C25.6169 27.3679 24.0908 28 22.4995 28C20.9082 28 19.3821 27.3679 18.2569 26.2426C17.1317 25.1174 16.4995 23.5913 16.4995 22ZM13.4995 38.5H31.4995C31.4433 34.7641 28.3964 31.75 24.6417 31.75H20.3573C16.6073 31.75 13.5604 34.7641 13.4995 38.5ZM11.9995 38.6078C11.9995 33.9906 15.7401 30.25 20.3573 30.25H24.6417C29.2589 30.25 32.9995 33.9906 32.9995 38.6078C32.9995 39.3766 32.3761 40 31.6073 40H13.3917C12.6229 40 11.9995 39.3766 11.9995 38.6078Z"
        fill="#5F53B5"
      />
    </svg>
  );
};

export default Avatar;
