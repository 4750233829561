const CollapseButton = () => {
  return (
    <svg
      width="76"
      height="40"
      viewBox="0 0 76 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="19" width="76" height="1" fill="#F2F2F2" />
      <rect x="18.5" y="0.5" width="39" height="39" rx="19.5" fill="white" />
      <rect
        x="18.5"
        y="0.5"
        width="39"
        height="39"
        rx="19.5"
        stroke="#102B42"
      />
      <path
        d="M32.3542 26.3529L38.3542 20.3529C38.5479 20.1591 38.5479 19.8404 38.3542 19.6466L32.3542 13.6466C32.1604 13.4529 31.8417 13.4529 31.6479 13.6466C31.4542 13.8404 31.4542 14.1591 31.6479 14.3529L37.2948 19.9997L31.6479 25.6466C31.4542 25.8404 31.4542 26.1591 31.6479 26.3529C31.8417 26.5466 32.1604 26.5466 32.3542 26.3529ZM38.3542 26.3529L44.3542 20.3529C44.5479 20.1591 44.5479 19.8404 44.3542 19.6466L38.3542 13.6466C38.1604 13.4529 37.8417 13.4529 37.6479 13.6466C37.4542 13.8404 37.4542 14.1591 37.6479 14.3529L43.2948 19.9997L37.6479 25.6466C37.4542 25.8404 37.4542 26.1591 37.6479 26.3529C37.8417 26.5466 38.1604 26.5466 38.3542 26.3529Z"
        fill="#102B42"
      />
    </svg>
  );
};

export default CollapseButton;
