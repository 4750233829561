import React from "react";

const ChartIcon = () => {
  return (
    <svg
      width="44"
      height="40"
      viewBox="0 0 44 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="hover:bg-neutral-100 rounded-md"
    >
      <g clipPath="url(#clip0_10429_86155)">
        <path
          d="M24.1667 12.7083C24.1667 12.0884 23.6616 11.5833 23.0417 11.5833H21.7917C21.1718 11.5833 20.6667 12.0884 20.6667 12.7083V26.4583C20.6667 27.0782 21.1718 27.5833 21.7917 27.5833H23.0417C23.6616 27.5833 24.1667 27.0782 24.1667 26.4583V12.7083ZM20.4167 12.7083C20.4167 11.9493 21.0327 11.3333 21.7917 11.3333H23.0417C23.8007 11.3333 24.4167 11.9493 24.4167 12.7083V26.4583C24.4167 27.2173 23.8007 27.8333 23.0417 27.8333H21.7917C21.0327 27.8333 20.4167 27.2173 20.4167 26.4583V12.7083ZM17.9167 20.2083C17.9167 19.5884 17.4116 19.0833 16.7917 19.0833H15.5417C14.9218 19.0833 14.4167 19.5884 14.4167 20.2083V26.4583C14.4167 27.0782 14.9218 27.5833 15.5417 27.5833H16.7917C17.4116 27.5833 17.9167 27.0782 17.9167 26.4583V20.2083ZM14.1667 20.2083C14.1667 19.4493 14.7827 18.8333 15.5417 18.8333H16.7917C17.5507 18.8333 18.1667 19.4493 18.1667 20.2083V26.4583C18.1667 27.2173 17.5507 27.8333 16.7917 27.8333H15.5417C14.7827 27.8333 14.1667 27.2173 14.1667 26.4583V20.2083ZM29.2917 14.0833H28.0417C27.4218 14.0833 26.9167 14.5884 26.9167 15.2083V26.4583C26.9167 27.0782 27.4218 27.5833 28.0417 27.5833H29.2917C29.9116 27.5833 30.4167 27.0782 30.4167 26.4583V15.2083C30.4167 14.5884 29.9116 14.0833 29.2917 14.0833ZM26.6667 26.4583V15.2083C26.6667 14.4493 27.2827 13.8333 28.0417 13.8333H29.2917C30.0507 13.8333 30.6667 14.4493 30.6667 15.2083V26.4583C30.6667 27.2173 30.0507 27.8333 29.2917 27.8333H28.0417C27.2827 27.8333 26.6667 27.2173 26.6667 26.4583Z"
          fill="#081521"
          stroke="#081521"
        />
      </g>
      <defs>
        <clipPath id="clip0_10429_86155">
          <rect width="43.999" height="40" rx="6" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default ChartIcon;
